import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils";
import { Sheet } from "@/utils/entities";
import { Loader2Icon, Plus } from "lucide-react";
import { useEffect, useState } from "react";

const defaultValues = {
  name: "",
  expire_date: "",
};

type FormSchemaType = typeof defaultValues;

type Props = {
  sheet?: Sheet;
  onSave: (data: FormSchemaType) => void;
  onClose: () => void;
};

const ModalFormSheet: React.FC<Props> = ({ onClose, onSave, sheet }) => {
  const { toast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState<FormSchemaType>(defaultValues);
  const [formError, setFormError] = useState<FormSchemaType | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (sheet) {
      setFormData({ expire_date: sheet.expire_date, name: sheet.name });
      setIsOpen(true);
    }
  }, [sheet]);

  const handleChangeInput = (key: keyof FormSchemaType, value: string) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
    setFormError(undefined);
    setFormData(defaultValues);
  };

  function validateForm() {
    const errors = {} as any;

    if (!formData?.name) {
      errors.name = "Campo obrigatório";
    }

    if (!formData?.expire_date) {
      errors.expire_date = "Campo obrigatório";
    }

    if (Object.keys(errors).length > 0) {
      setFormError(errors);

      return { isValid: false };
    }

    return { isValid: true };
  }

  const onSubmit = async () => {
    const { isValid } = validateForm();

    if (!isValid) {
      return;
    }

    try {
      setIsSubmitting(true);

      if (sheet) {
        await onSave(formData);
        toast({
          variant: "success",
          description: "Ficha atualizada",
        });
      } else {
        await onSave(formData);
        toast({
          variant: "success",
          description: "Ficha criada com sucesso",
        });
      }

      setIsSubmitting(false);

      handleClose();
    } catch (error) {
      setIsSubmitting(false);

      toast({
        variant: "destructive",
        description: "Não foi possível criar a ficha",
      });
    }
  };

  return (
    <>
      <Button onClick={onOpen} className="">
        <Plus className="mr-2" /> Nova ficha do zero
      </Button>
      <Dialog open={isOpen} onOpenChange={handleClose}>
        <DialogContent className="sm:max-w-md bg-white">
          <DialogHeader>
            <DialogTitle>
              {!sheet?.id ? "Crie uma nova ficha" : "Editar ficha"}
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col mt-5 gap-6">
            <div className="grid gap-3">
              <Label htmlFor="name">Nome da Ficha</Label>
              <Input
                id="name"
                value={formData?.name}
                className={
                  formError?.name && "border-red-600 border-[1px] border-solid"
                }
                onChange={(e) => handleChangeInput("name", e.target.value)}
              />
              {formError?.name && (
                <div className="text-sm text-red-600 mt-0">
                  {formError?.name}
                </div>
              )}
            </div>

            <div className="grid gap-3">
              <Label htmlFor="expire_date">Data de expiração</Label>
              <Input
                id="expire_date"
                type="date"
                value={formData?.expire_date}
                className={cn("block",
                  formError?.expire_date &&
                  "border-red-600 border-[1px] border-solid"
                )}
                onChange={(e) =>
                  handleChangeInput("expire_date", e.target.value)
                }
              />
              {formError?.expire_date && (
                <div className="text-sm text-red-600 mt-0">
                  {formError?.expire_date}
                </div>
              )}
            </div>
          </div>
          <DialogFooter className="sm:justify-end">
            <Button
              type="button"
              variant="secondary"
              onClick={handleClose}
              disabled={isSubmitting}
            >
              Cancelar
            </Button>
            <Button type="button" onClick={onSubmit} disabled={isSubmitting}>
              {isSubmitting && (
                <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
              )}
              Salvar
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalFormSheet;
