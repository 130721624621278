import ButtonBack from "@/components/button-back";
import RootLayout from "@/components/layout";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Exercise } from "@/utils/entities";
import { useParams } from "react-router-dom";
import { SheetService } from "@/services/api/sheet";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "@/components/ui/loading";
import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import SheetTrainingContent from "./components/sheet/sheet-trainings-content";
import { Button } from "@/components/ui/button";
import { FilePlusIcon } from "lucide-react";
import SheetPdf from "./components/sheet/sheet-pdf";
import html2pdf from 'html2pdf.js';

export default function SheetCreateScreen() {
  const params = useParams();
  const { toast } = useToast();
  const [key, setKey] = useState(Math.random());

  const { data, refetch, isLoading, isFetching } = useQuery({
    retry: 3,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(params?.sheetId),
    queryKey: ["sheet_details", params?.sheetId as string],
    queryFn: () => SheetService.getOne(params?.sheetId as string),
  });

  async function reloadTraining() {
    await refetch();

    setKey(Math.random());
  }

  async function handleExerciseInTraining(exercise: Exercise) {
    try {
      if (!exercise?.execution) {
        return;
      }

      const { exerciseId, trainingId } = exercise?.execution;

      await SheetService.removeExerciseOfTraining({
        sheetId: params?.sheetId,
        trainingId,
        exerciseId,
      });

      await refetch();

      toast({
        variant: "success",
        description: "Exerício removido com sucesso.",
      });

      setKey(Math.random());
    } catch (error: any) {
      toast({
        variant: "destructive",
        description:
          error?.response?.data?.message ?? "Ocorreu um erro inesperado",
      });
    }
  }

	function handleGeneratePdf() {
		if (!data) return;

		const element = (document.getElementById('sheet-pdf-content') as any)?.cloneNode(true);

		if (!element) {
			toast({
        variant: "destructive",
        description: 'Ocorreu um erro ao gerar o PDF do treino.'
      });

			return console.error('Ocorreu um erro ao gerar o PDF do treino.');
		}

		const marginVertical = 5;
		const marginHorizontal = 2;
		const options = {
			margin:	[marginVertical, marginHorizontal, marginVertical, marginHorizontal],
			filename:	`ficha_${data?.name?.toLocaleLowerCase()?.replace(/ /g,"_")}.pdf`,
			html2canvas: { scale: 2 },
			pagebreak: {
        mode: ['avoid-all', 'css', 'legacy']
    	},
		}

		element?.classList?.remove('hidden');

		html2pdf(element, options);
	}

  return (
    <RootLayout>
      {isLoading ? (
        <Loading />
      ) : (
				<>
					<Card className="bg-gray-800 p-0">
						<CardHeader>
							<CardTitle className="text-gray-200 flex flex-col md:flex-row md:items-center justify-start">
								<div className="flex items-center flex-1">
									<ButtonBack />
									{data?.name}
								</div>

								<Button className="gap-2" onClick={handleGeneratePdf}>
									<FilePlusIcon size={15} /> Gerar PDF
								</Button>
							</CardTitle>
						</CardHeader>

						<CardContent>
							<SheetTrainingContent
								key={+key}
								data={data}
								isLoading={isFetching}
								sheetId={params?.sheetId}
								onSuccess={reloadTraining}
								onRemoveExercise={handleExerciseInTraining}
							/>
						</CardContent>
					</Card>

					<div id="sheet-pdf-content" className="hidden">
						{data ? <SheetPdf sheet={data} /> : null}
					</div>
				</>
      )}

    </RootLayout>
  );
}
