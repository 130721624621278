import RootLayout from '@/components/layout'
import ModalConfirmDelete from '@/components/modal-confirm'
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Loading } from '@/components/ui/loading'
import { useToast } from '@/components/ui/use-toast'
import { StudentService } from '@/services/api/student'
import { UserService } from '@/services/api/user'
import { UserStatus } from '@/utils/entities'
import { getToastErrorContent } from '@/utils/functions/api-error'
import { getDateFormatText } from '@/utils/functions/date-transform'
import { useQuery } from '@tanstack/react-query'
import { differenceInYears } from 'date-fns'
import { EditIcon, FileCheckIcon, FilesIcon, RefreshCwIcon, TrashIcon } from 'lucide-react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AttachmentList from './components/attachment/attachment-list'
import FoodPlanList from './components/food-plan/food-plan-list'
import ModalChangeGroup from './components/modal/modal-change-group'
import ModalPhysicalEvaluation from './components/modal/modal-physical-evaluation'
import ModalUserFormAnswers from './components/modal/modal-user-form-answers'
import SheetList from './components/sheet/sheet-list'

type TypesModal =
  | 'UPDATE_STATUS'
  | 'FORM_ANSWERS'
  | 'PHYSICAL_EVALUATION'
  | 'UPDATE_GROUP'
	| 'DELETE_USER'

export default function StudentEditScreen() {
  const { id } = useParams()
  const { toast } = useToast()
	const navigate = useNavigate();
  const [modalType, setModalType] = useState<TypesModal | null>(null)
  const [isReloading, setIsReloading] = useState(false)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['student', id],
    refetchOnWindowFocus: false,
    queryFn: () => StudentService.getOne(id as string),
  })

  const onUpdateStatus = () => async () => {
    try {
      if (!data || !data.user) {
        return
      }

      const { user } = data

      const status =
        user.status === UserStatus.ACTIVE
          ? UserStatus.INACTIVE
          : UserStatus.ACTIVE

      await UserService.update({ id: user.id, status })

      refetch()

      toast({
        variant: 'success',
        description: 'Aluno atualizado com sucesso.',
      })
    } catch (error) {
      toast(getToastErrorContent(error))
    } finally {
      setModalType(null)
    }
  }

  const onSave = async ({
    contract_date,
    groupId,
  }: {
    contract_date: string
    groupId: string
  }) => {
    if (data && data.user) {
      setIsReloading(true)

      handleOpenModal(null)

      await StudentService.update({
        ...data,
        groupId,
        user: { ...data.user, contract_date: contract_date },
      })

      refetch()

      setIsReloading(false)
    }
  }

	const onSubmitDelete = () => async () => {
		try {
			if (!id) {
				throw new Error('Id do student não encontrado');
			}

			setModalType(null);

			setIsReloading(true);

      await StudentService.delete(id)

      toast({
				variant: 'success',
        description: 'Aluno excluído com sucesso.',
      });

			setIsReloading(false);

			navigate(-1);
    } catch (error) {
			setModalType('DELETE_USER');

      toast(getToastErrorContent(error))
    }
	}

  function handleOpenModal(type: TypesModal | null) {
    setModalType(type)
  }

  function Header() {
    return (
      <CardHeader>
        <CardTitle className="flex flex-col-reverse text-gray-200 lg:items-center lg:justify-between lg:flex-row">
          <div className="flex flex-row">
            {data?.sex === 'M' ? '🙋‍♂️ ' : '🙋‍♀️ '}
            {data?.user.name + ' ' + data?.user.surname} (
            {data?.date_of_birth
              ? differenceInYears(
                  new Date(),
                  new Date(data?.date_of_birth || '')
                )
              : 0}{' '}
            anos)
            {data?.user.status === UserStatus.ACTIVE ? ' 🟢 ' : ' 🔴 '}
          </div>

          <div className="flex gap-3">
            <Button
              size="sm"
              variant="outline"
              disabled={
                !data?.form_answer || Object.keys(data.form_answer).length === 0
              }
              className="mb-4 lg:mb-0"
              onClick={() => handleOpenModal('FORM_ANSWERS')}
            >
              <FileCheckIcon className="h-4 w-4 mr-2" />
              {data?.form_answer && Object.keys(data.form_answer).length > 0
                ? 'Formulário respondido'
                : 'Formulário não respondido'}
            </Button>

            <Button
              size="sm"
              variant="outline"
              className="mb-4 lg:mb-0"
              onClick={() => handleOpenModal('PHYSICAL_EVALUATION')}
            >
              <FilesIcon className="h-4 w-4 mr-2" />
              Avaliações físicas
            </Button>

            <Button
              size="sm"
              variant="default"
              className="mb-4 lg:mb-0"
              onClick={() => handleOpenModal('UPDATE_STATUS')}
            >
              <RefreshCwIcon className="h-4 w-4 mr-2" />

              {data?.user?.status === UserStatus.INACTIVE
                ? 'Ativar'
                : 'Inativar'}
            </Button>
          </div>
        </CardTitle>
      </CardHeader>
    )
  }

  return (
    <RootLayout>
      {isLoading || isReloading ? (
        <Loading />
      ) : (
        <div className="flex flex-col">
          <Card className="bg-gray-800 mt-10">
            <Header />
            <CardContent className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 w-full overflow-auto">
              <div className="text-white flex flex-col">
                <label className="font-bold">Email</label>
                {data?.user?.email}
              </div>
              <div className="text-white flex flex-col">
                <label className="font-bold">Telefone</label>
                {data?.user?.phone}
              </div>
              <div className="text-white flex flex-col">
                <label className="font-bold">Data de nascimento</label>
                {getDateFormatText(data?.date_of_birth)}
              </div>
              <div className="text-white flex flex-col">
                <label className="font-bold">Profissão</label>
                {data?.profession}
              </div>
              <div className="text-white flex flex-col">
                <label className="font-bold">Carga horária</label>
                {data?.weekly_work_hours}
              </div>
              <div className="text-white flex flex-col">
                <label className="font-bold">Nome da academia</label>
                {data?.gym_name}
              </div>
              <div className="text-white flex flex-col md:col-span-2">
                <label className="font-bold">
                  Como ficou sabendo da consultoria?
                </label>
                {data?.how_know_about_us}
              </div>
            </CardContent>

            <hr />

            <CardFooter className="p-6 flex items-center justify-between">
              <div className="flex-1 text-white flex flex-col">
                <label className="font-bold">Grupo</label>

                {data?.group?.name}
              </div>

              <div className="flex-1 text-white flex flex-col">
                <label className="font-bold">Vencimento do contrato</label>
                {getDateFormatText(data?.user?.contract_date)}
              </div>

              <Button
                className="max-w-fit"
                onClick={() => handleOpenModal('UPDATE_GROUP')}
              >
                <EditIcon className="mr-2" size={20} />
                Atualizar contrato
              </Button>
            </CardFooter>
          </Card>

          <SheetList />

          <div className="flex flex-col lg:flex-row gap-10 mt-10">
            <FoodPlanList />

            <AttachmentList />
          </div>

					<Card className="bg-gray-800 mt-10">
						<CardHeader>
							<CardTitle className="flex text-gray-200 items-center justify-between">
								Deletar aluno
							</CardTitle>
						</CardHeader>
						<CardContent className="grid gap-10 w-full overflow-auto text-white">
							Cuidado ao excluir um aluno, isso irá apagar todo o conteúdo cadastrado para ele além de bloquear o acesso ao app.
						</CardContent>
						<CardFooter>
							<Button
								size="sm"
								variant="outline"
								className="mb-4 text-white lg:mb-0"
								onClick={() => handleOpenModal('DELETE_USER')}
							>
								<TrashIcon className="h-4 w-4 mr-2" />
								Deletar
							</Button>
						</CardFooter>
					</Card>
        </div>
      )}

      <ModalChangeGroup
        isOpen={modalType === 'UPDATE_GROUP' && !!data}
        onSave={onSave}
        student={data}
        onClose={() => handleOpenModal(null)}
      />

      <ModalConfirmDelete
        onCancel={() => handleOpenModal(null)}
        isVisible={modalType === 'UPDATE_STATUS'}
        onContinue={onUpdateStatus()}
        description={
          data?.user.status === UserStatus.ACTIVE ? (
            <>
              Isso irá <strong>bloquear</strong> o acesso do aluno ao
              aplicativo.
            </>
          ) : (
            <>
              Isso irá <strong>desbloquear</strong> o acesso do aluno ao
              aplicativo.
            </>
          )
        }
      />

		<ModalConfirmDelete
        onCancel={() => handleOpenModal(null)}
        isVisible={modalType === 'DELETE_USER'}
        onContinue={onSubmitDelete()}
        description={
          <>
						Isso irá <strong>excluir</strong> todos os dados do aluno da plataforma bem como <strong>remover</strong> o acesso do aluno ao aplicativo.
					</>
        }
      />

      <ModalUserFormAnswers
        userName={data?.user?.full_name}
        isVisible={modalType === 'FORM_ANSWERS'}
        form_answer={data?.form_answer}
        handleClose={() => handleOpenModal(null)}
      />

      <ModalPhysicalEvaluation
        data={data?.physical_assessments}
        isVisible={modalType === 'PHYSICAL_EVALUATION'}
        handleClose={() => handleOpenModal(null)}
      />
    </RootLayout>
  )
}
