import { Separator } from '@/components/ui/separator';
import { cn } from '@/utils';
import type { Sheet } from '@/utils/entities';

type Props = {
	sheet: Sheet;
}

export default function SheetPdf({ sheet }: Props) {
	return (
		<div className='min-h-[200px] text-black bg-white px-3'>
			<div className='flex items-center justify-center uppercase'><h1>{sheet.name}</h1></div>

			<div className="flex flex-col mt-8">
				{sheet.trainings.map((training) => (
					<div className="flex flex-col border mb-8">
						<div className="flex justify-center">
							<span className='uppercase font-bold text-md'>{training.name}</span>
						</div>

						<Separator className='mt-4' />

						<div className="flex flex-col px-4">
							{training.exercises.map((exercise, index) => {
								const isLastIndex = index === training?.exercises?.length - 1;

								return (
									<div className="flex flex-col mt-3">
										<div className="flex flex-col flex-1">
											<div className="flex items-center mb-4 gap-3">
												<span className='text-sm'>{(exercise?.execution?.order || 0) + 1 + "º"} - {exercise?.name} - {exercise?.type?.name}</span>
											</div>

											<div className={cn("flex-1 gap-2 grid grid-cols-2 pb-4", isLastIndex ? 'pb-5' : '')}>
												<div className="flex flex-1 flex-col">
													<span className="text-[14px]">Series</span>
													<span className="text-[12px]">
														{exercise?.execution?.sets || "-"}
													</span>
												</div>

												<div className="flex flex-1 flex-col">
													<span className="text-[14px]">Repet.</span>
													<span className="text-[12px]">
														{exercise?.execution?.repetitions || "-"}
													</span>
												</div>

												<div className="flex flex-1 flex-col">
													<span className="text-[14px]">Cadência</span>
													<span className="text-[12px]">
														{exercise?.execution?.cadence || "-"}
													</span>
												</div>

												<div className="flex flex-1 flex-col">
													<span className="text-[14px]">Intervalo</span>
													<span className="text-[12px]">
														{exercise?.execution?.interval || "-"}
													</span>
												</div>

												<div className="flex flex-1  flex-col">
													<span className="text-[14px]">Carga Sugerida</span>
													<span className="text-[12px]">
														{exercise?.execution?.load || "-"}
													</span>
												</div>

												<div className="flex flex-1 flex-col">
													<span className="text-[14px]">Observação</span>
													<span className="text-[12px]">
														{exercise?.execution?.observation || "-"}
													</span>
												</div>
											</div>
										</div>

										{!isLastIndex && <Separator className='my-1' />}
									</div>
								)
							})}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
