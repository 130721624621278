import RootLayout from "@/components/layout";
import { getAllExercises, ExerciseService } from "@/services/api/exercise";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import ExerciseItem from "./components/exercise-item";
import ExerciseFormCreate from "./components/form-create";
import { Exercise, UserType } from "@/utils/entities";
import { Card } from "@/components/ui/card";
import { ExerciseTypeService } from "@/services/api/exercise-type";
import FormVideoUpload from "./components/form-video-upload";
import Spinner from "@/components/ui/spinner";
import { useSearchParams } from "react-router-dom";
import { ArrowDownIcon } from "lucide-react";
import Search from "./components/search";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import ModalConfirmDelete from "@/components/modal-confirm";
import { useToast } from "@/components/ui/use-toast";
import { useUserProvider } from "@/utils/providers/user-provider";

const exerciseCategories = [
  { id: "all", name: "Todos os exercícios" },
  { id: "my", name: "Meus exercícios" },
  { id: "platform", name: "Exercícios da plataforma" },
];

export default function ExerciseListScreen() {
	const { user } = useUserProvider();
  const { toast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type") || undefined;
  const name = searchParams.get("name") || undefined;
  const category = searchParams.get("category") || "all";

  const [exercise, setExercise] = useState<Exercise | null>(null);
  const [exerciseToDelete, setExerciseToDelete] = useState<Exercise | null>(
    null
  );
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDrawerOpened, setDrawerOpened] = useState(false);
  const [isDialogVideoOpened, setIsDialogVideoOpened] = useState(false);

  const {
    data,
    refetch,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["execises", type, name, category],
    initialPageParam: 1,
    refetchOnWindowFocus: false,
    queryFn: ({ pageParam: page = 1 }) =>
      getAllExercises({ page, name, type, category }),
    getNextPageParam: ({ pagination }) => pagination?.nextPage,
  });

  const { data: exerciseTypes, refetch: refetchExercisesTypes } = useQuery({
    queryKey: ["execise_types_only_data"],
    retry: 2,
    retryDelay: 3000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryFn: ExerciseTypeService.getOnlyData,
  });

  function handleCloseForms() {
    setExercise(null);
    setDrawerOpened(false);
    setIsDialogVideoOpened(false);
  }

  function handleEditExercise(item: Exercise) {
    setExercise(item);

    setDrawerOpened(true);
  }

  function handleDelete(item: Exercise) {
    setExerciseToDelete(item);
  }

  const confirmDeleteExercise = async () => {
    setIsLoadingDelete(true);
    try {
      if (exerciseToDelete) {
        await ExerciseService.delete(exerciseToDelete);
        setExerciseToDelete(null);
        refetch();
        refetchExercisesTypes();
      }

      toast({
        variant: "success",
        description: `Exerciceo deletado com sucesso.`,
      });
    } catch (err) {
      toast({
        variant: "destructive",
        description: `Não foi possível execluir o exerciceo.`,
      });
    }
    setIsLoadingDelete(false);
  };

  function handleUploadVideoExercise(item: Exercise) {
    setExercise(item);

    setIsDialogVideoOpened(true);
  }

  function handleSuccessfullyVideoUploaded() {
    refetch();
    refetchExercisesTypes();
    setExercise(null);
    setDrawerOpened(false);
    setIsDialogVideoOpened(false);
  }

  function handleTab(type: string) {
    if (type.trim()) {
      searchParams.set("type", type);
    } else {
      searchParams.delete("type");
    }

    setSearchParams(searchParams, { replace: true });
  }

  function handleSearch(value: string) {
    if (value) {
      searchParams.set("name", value);
    } else {
      searchParams.delete("name");
    }

    setSearchParams(searchParams, { replace: true });
  }

  function handleChangeCategory(category?: string) {
    if (!category || category === "all") {
      searchParams.delete("category");
    } else {
      searchParams.set("category", category);
    }

    setSearchParams(searchParams, { replace: true });
  }

  function handleClearSearch() {
    if (searchParams.has("name")) {
      searchParams.delete("name");

      setSearchParams(searchParams, { replace: true });
    }
  }

  const exercises = data?.pages.flatMap((page) => page.exercises);

  return (
    <RootLayout>
      <div className="h-full">
        <div className="flex">
          <div className="flex gap-4">
            <Select value={type} onValueChange={handleTab}>
              <SelectTrigger className="w-[240px] text-white">
                <SelectValue
                  placeholder="Filtro de tipos de exercícios"
                  className="text-white"
                />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={" "}>Todos</SelectItem>
                {exerciseTypes?.map((item) => (
                  <SelectItem key={item.id} value={item.id}>
                    {item.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select value={category} onValueChange={handleChangeCategory}>
              <SelectTrigger className="w-[260px] text-white">
                <SelectValue
                  placeholder="Filtro de categorias de exercícios"
                  className="text-white"
                />
              </SelectTrigger>
              <SelectContent>
                {exerciseCategories?.map((item) => (
                  <SelectItem key={item.id} value={item.id}>
                    {item.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="ml-auto">
            <Button variant="secondary" onClick={() => setDrawerOpened(true)}>
              <PlusCircledIcon className="mr-2 h-4 w-4" />
              Adicionar exercício
            </Button>
          </div>
        </div>

        <Search
          defaultValue={name}
          onClear={() => handleClearSearch()}
          onSearch={(value) => handleSearch(value)}
        />

        {isFetching && !exercises?.length ? (
          <div className="flex h-[40%] items-center justify-center text-zinc-300">
            <Spinner className="mr-2" />
            <span className="text-sm">Carregando</span>
          </div>
        ) : null}

        {!isFetching && !exercises?.length ? (
          <div className="flex flex-col h-[40%] items-center justify-center gap-2">
            <span className="text-zinc-200">
              Não foram encontrados resultados para a sua busca
            </span>
            <span>😞</span>
          </div>
        ) : null}

        <div className="grid grid-cols-[repeat(auto-fill,280px)] justify-between gap-5">
          {exercises?.map((item) => (
            <Card key={item.id} className="bg-slate-900 border-slate-800">
              <ExerciseItem
                exercise={item}
                onEdit={() => handleEditExercise(item)}
                onVideo={() => handleUploadVideoExercise(item)}
                onDelete={() => handleDelete(item)}
								canEditOrDelete={user.type === UserType.ADMIN || user?.professional?.id === item?.professionalId}
              />
            </Card>
          ))}
        </div>

        {hasNextPage ? (
          <div className="grid justify-center">
            <Button
              variant="secondary"
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
            >
              {isFetchingNextPage ? (
                <Spinner className="h-4 w-4 mr-2" />
              ) : (
                <ArrowDownIcon className="h-4 w-4 mr-2" />
              )}
              Ver mais
            </Button>
          </div>
        ) : null}
      </div>

      {isDrawerOpened ? (
        <ExerciseFormCreate
          isOpened={true}
          onCancel={handleCloseForms}
          exercise={exercise}
          onSuccessful={handleSuccessfullyVideoUploaded}
        />
      ) : null}

      {isDialogVideoOpened ? (
        <FormVideoUpload
          isOpened={true}
          onCancel={handleCloseForms}
          exerciseId={String(exercise?.id)}
          onSuccessful={handleSuccessfullyVideoUploaded}
        />
      ) : null}

      <ModalConfirmDelete
        isVisible={!!exerciseToDelete}
        onCancel={() => setExerciseToDelete(null)}
        onContinue={confirmDeleteExercise}
        title="Confirmação de deletação"
        isLoading={isLoadingDelete}
        description={
          <>
            Você tem certeza que quer
            <strong> EXCLUIR {exerciseToDelete?.name}</strong>?
          </>
        }
      />
    </RootLayout>
  );
}
